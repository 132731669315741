<template>
  <div class="flex column center" style="margin-bottom:4rem;">
    <div class="new_box">
      <div class="titlee">庆贺，全项7大类一次性通过！根据环境损害鉴定新资质要求，历思顺利进行重新审核登记！</div>
      <p class="time">2019.06.24</p>
      <p class="space_content" >
        近日，在司法部“商两高”的背景下，环境损害鉴定归入“四大类”，基于环境损害鉴定资质的新要求，历思顺利通过专家评审，原环境物证司法鉴定重新登记为环境损害鉴定7项资质。
      </p>
      <p class="space_content" style="font-weight: 600">
        历思环境损害鉴定的概况...
      </p>
      <p class="space_content" >
        自此，福建历思司法鉴定所已成为国内资质最齐全的司法鉴定机构之一（17项资质）。历思于2006年取得全国第一批环境物证鉴定牌照；开展环境损害鉴定工作已有十多年，为国内最早介入、最早研究、最早实践的机构之一。2016年历思成为环保部环境损害鉴定评估推荐机构，并具有司法鉴定和环保部推荐机构的双重资质。
      </p>
      <p class="space_content" >
        十多年来，历思承办了泉港皮革案（8.13群体性事件）、德化含酚废水非法倾倒案、厦门首起环境公益诉讼案、2018福建龙岩危废案等福建重大特大与敏感环境案件。历思注重技术、法律与国情在鉴定中的有机结合，所作鉴定意见基本为法庭所采信。为支撑环境业务开展，历思建设了目前福建省内规模最大、配备最先进齐全的独立物证中心，并集成了研究、检测、鉴定、培训、认证、咨询能力。
      </p>
      <p class="space_content" >
        2015年7月30日，历思在漳州市中级人民法院通过现场与远程视频方式为全省人民法院、人民检察院、环保局等相关单位作《环境损害-法-鉴定评估》培训。
      </p>
      <p class="space_content" >
        2017年历思承担的《环境损害鉴定评估通用规范》福建省地方标准，于2017年12月发布并从2018年3月25日起正式实施。国家还未出台正式的相关标准，该标准是国内该领域的第一个标准。目前，国家环境损害司法鉴定标准还在制定中。
      </p>
      <p class="space_content" >
        2018年1月，环保部正式批准我所与漳州开发区联合申报的《环境证据化规范研究与应用试点》项目，并列入环保部环境损害鉴定评估技术试点。该试点为目前国内首个研究环境执法与司法衔接的环保部试点，也是福建省唯一的国家级环境损害鉴定评估技术试点。
      </p>

      <p class="space_content" style="font-weight: 600">
        历思换发新证的过程...
      </p>
      <p class="space_content" >
        根据2018年12月5日，司法部办公厅下发《关于严格依法做好司法鉴定人和司法鉴定机构登记工作的通知》（司办通【2018】164号）（以下“通知”），将环境损害司法鉴定归入“四大类”，对已登记的“四类外”鉴定机构中所从事的司法鉴定业务属“四大类”鉴定事项的，要依法重新审核登记。
      </p>
      <p class="space_content" >
        重新审核登记的环境损害司法鉴定资质，对推进环境损害司法鉴定工作规范化、制度化、体系化有着重要意义，也对环境损害价值量化等工作提供了更坚实的法律依据。
      </p>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/24-1.png')">
        <p class="center_">（环境损害鉴定专家评审现场）</p>
      </div>

      <p class="space_content" >
        2019年3月13日至14日，司法厅和环保厅组织专家对历思环境损害司法鉴定进行重新审核登记的专家评审。经过为期两天严格系统的评审，历思在人员配置、仪器设备和检测能力等方面均得到评审专家们的认可。历思一次性通过了环境损害司法鉴定全项7大类资质的评审，并于2019年5月21日换发新的司法鉴定许可证。
      </p>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/24-2.png')">
        <p class="center_">（历思换发新的司法鉴定许可证）</p>
      </div>

      <p class="space_content" >
        历思环境损害司法鉴定获批的项目包括：污染物性质鉴定、地表水和沉积物环境损害鉴定、空气污染环境损害、土壤与地下水环境损害、近海海洋与海岸带环境损害、生态系统环境损害（森林、农田）鉴定、其他环境损害（噪声）鉴定等。
      </p>
      <p class="space_content" >
        此次顺利通过了环境损害司法鉴定资质的重新审核登记，历思在环境损害鉴定方面，实现了7大类全覆盖！今后在环境损害司法鉴定新起点上，历思将继续提升自身的技术水平，脚踏实地，不断学习，务实创新，利用我们的专业知识和技术，为社会提供更优质的环境损害鉴定服务，争取在环境损害司法鉴定领域做出更多的贡献！
      </p>

    </div>
    <div class="flex wrap .col442" style="width:100%;text-align:left;margin-top:1rem">
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex" style="margin-right:10%">
          <p style="white-space:nowrap">上一篇：</p>
          <router-link class="a" :to="'/news/lsnews/23'"><p class="ellipsis1">号外，号外，我们又和一所高校握手了！</p>
          </router-link>
        </div>
      </div>
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex">
          <p style="white-space:nowrap">下一篇：</p>
          <router-link class="a" :to="'/news/lsnews/25'"><p class="ellipsis1">庆贺！历思物证科技产研体系成员企业——堃晟，通过了“CMA”授权颁发的珠宝玉石及贵金属的能力资格认证！A”授权颁发的珠宝玉石及贵金属的能力资格认证！！</p>
          </router-link>

        </div>
      </div>
      <div class="col2 flex  end1 center">
        <router-link class="a" style="color:#fff" :to="'/news/lsnews'">
          <el-button type="primary">返回列表</el-button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {}
    }
  },
}
</script>
<style>
.redText {
  background: red;
  color: white;
  font-size: 20px;
  font-weight: 600;
}

.titleText {
  font-size: 20px;
  font-weight: 600;
}
</style>
